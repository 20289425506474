<template>
  <div class="app-layout">
    <!-- Main Content -->
    <div class="main-content" @click="closeAllDropdowns">
      <div class="history-page">
        <!-- Header and Filters -->
        <div class="content_header">
          <h2>Your History</h2>
        </div>
        <div class="filters-container">
          <div class="filters">
            <!-- Platform filter -->
            <el-select 
              v-model="query.platform" 
              placeholder="All Platforms" 
              value-key="value" 
              filterable 
              clearable 
              @change="chanagePlatform" 
              @clear="((val)=>{clear(val, 'platform')})"
              size="small"
              class="filter-select"
            >
              <el-option
                v-for="(item,index) in platformList"
                :key="item.value"
                :label="item.title"
                :value="item.value"
                :index="index">
              </el-option>
            </el-select>
            <!-- Site filter -->
            <el-select 
              v-model="query.site" 
              placeholder="All Sites"  
              filterable 
              clearable 
              @change="chanageSite" 
              @clear="((val)=>{clear(val, 'site')})"
              size="small"
              class="filter-select"
            >
              <el-option
                v-for="item in siteList"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            <!-- Search input -->
            <el-input 
              placeholder="Search by SKU..." 
              v-model="query.skuNumber" 
              @change="chanageSku"  
              prefix-icon="el-icon-search"
              class="search-input"
            ></el-input>
            <!-- Operate button -->
            <div class="action-buttons">
              <!-- <el-button size="small" plain @click="downloadByBatch" icon="el-icon-download" >
                Download
              </el-button> -->
              <el-button size="small" plain @click="deleteByBatch" icon="el-icon-delete">
                Remove
              </el-button>
            </div>
          </div>
        </div>

        <!-- Product List -->
        <div class="product-list">
          <div 
            v-for="product in dataList" 
            :key="product.id" 
            class="product-item"
          >
            <div class="checkbox-col">
              <input 
                type="checkbox" 
                :id="`select-${product.id}`" 
                :checked="selectedItemIdList.includes(product.id)"
                @change="toggleSelection(product.id)"
              >
            </div>
            
            <div class="image-col">
              <!-- <img :src="product.originalImageUrl" :alt="product.skuNumber"> -->
              <el-image 
                :src="product.originalImageUrl" 
                :alt="product.skuNumber"
                :preview-src-list="[product.originalImageUrl]" 
                :z-index="2000"
                fit="contain"
                style="width: 100%; height: 100%; max-width: none;"
              />
            </div>
            
            <div class="details-col">
              <div class="detail-row">
                <div class="detail-group">
                  <div class="detail-label">SKU</div>
                  <div class="detail-value">{{ product.skuNumber }}</div>
                </div>
                
                <div class="detail-group">
                  <div class="detail-label">SITE</div>
                  <div class="detail-value">{{ product.site }}</div>
                </div>
              </div>
              
              <div class="detail-row">
                <div class="detail-group">
                  <div class="detail-label">PLATFORM</div>
                  <div class="detail-value">{{ product.platform }}</div>
                </div>
                
                <div class="detail-group">
                  <div class="detail-label">CREATOR</div>
                  <div class="detail-value">{{ product.userName }}</div>
                </div>
              </div>
            </div>

            <div class="date-col">
              <div class="date-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="12 6 12 12 16 14"></polyline>
                </svg>
                {{ formatDate(product.createTime) }}
              </div>
              <div class="status-badge" :class="product.status.aname.toLowerCase()">
                <span class="status-dot"></span>
                {{ product.status.aname }}
              </div>
            </div>
            
            <div class="actions-col">

              <!-- <el-dropdown trigger="click" @command="(command) => handleCommand(command, product)"  placement="bottom-end" append-to-body>
                <button class="more-btn">...</button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="edit"><i class="el-icon-edit"></i>Edit</el-dropdown-item>
                  <el-dropdown-item command="download"><i class="el-icon-download"></i>Download</el-dropdown-item>
                  <el-dropdown-item command="remove"><i class="el-icon-delete"></i>Remove</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> -->
              <div class="dropdown-container">
                <button @click.stop="toggleDropdown(product.id)" class="more-btn">
                  ...
                </button>
                <div v-if="openDropdown === product.id" class="dropdown-menu" :class="{'dropdown-up': isLastItem(product.id)}">
                  <button @click.stop="editData(product)" class="dropdown-item">
                    <i class="el-icon-edit"></i>
                    Edit
                  </button>
                  <button @click.stop="downloadByRow(product)" class="dropdown-item">
                    <i class="el-icon-download"></i>
                    Download
                  </button>
                  <button @click.stop="deleteByRow(product)" class="dropdown-item">
                    <i class="el-icon-delete"></i>
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Empty state message -->
          <div v-if="dataList.length === 0" class="empty-state">
            <p>No data found matching your filters.</p>
          </div>
        </div>

        <div class="pageWrapper">
          <el-pagination
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            @prev-click="handlePrevClick"
            @next-click="handleNextClick"
            :current-page.sync="pageDomain.pageIndex"
            :page-size="pageDomain.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            layout="total,prev, pager, next, jumper,sizes"
            :total="pageDomain.recordCount"
          ></el-pagination>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as DownloadUtils from '@/utils/DownloadUtils.js';
export default {
  props: ['ifEditReturn'],
  data() {
    return {
      query:{
        platform: null,
        site: null,
        skuNumber: null
      },
      pageDomain: {
        pageIndex: 1,
        pageSize: 10,
        recordCount: 0
      },
      multipleSelection:[],
      dataList:[],
      searchLoading: false,
      btnLoading: false,
      accountList: [],
      siteList: [],
      statusList: [],
      platformList:[
        {
          "title":"Walmart",
          "value": "Walmart",
          "showFlag": false,
          "selectSiteList":["US","CA"]
        },
        {
          "title":"Walmart_DSV",
          "value": "Walmart DSV",
          "showFlag": false,
          "selectSiteList":["US"]
        },
        {
          "title":"Amazon",
          "value": "Amazon",
          "showFlag": false,
          "selectSiteList":["US","CA","DE","FR","UK"]
        },
        {
          "title":"Amazon_Vendor",
          "value": "Amazon Vendor",
          "showFlag": false,
          "selectSiteList":["US","CA","DE","FR","UK"]
        },
        {
          "title":"Temu",
          "value": "Temu",
          "showFlag": false,
          "selectSiteList":["ES","IT","FR","DE"]
        }
      ],
      pageSelectCount: 0,
      filterByList:[
        {
          title:'Sku.',
          value:"skuNo",
        },
        {
          title:'Platform Sku ID.',
          value:"platformSkuId",
        },
        {
          title:'Platform Sku Name.',
          value:"platformSkuName",
        }
      ],
      dialogVisible: false,
      selectedRow: null, // 存储选中的行数据
      loadingRow: null, // 用于跟踪正在加载的行
      selectedItemIdList: [],
      openDropdown: null,
    }
  },
  computed: {
    
  },
  watch: {
  },
  created(){
    //this.queryInit();
    this.searchData();
  },
  methods: {
    chanagePlatform(value){
      //埋点
      var logstr = "history_platfrom_click?platfrom=(" +value +")";
      this.addTrackingLog(logstr);
      if (value) {
        var _this = this;
        const index = _this.platformList.findIndex(option => option.value === value);
        _this.siteList = _this.platformList[index].selectSiteList;
        // 调用 Vuex
        _this.$store.commit('setCurrentQuery', _this.query);
        _this.searchData();
      }
    },
    chanageSite(){
      var _this = this;
      //埋点
      var logstr = "history_platfrom_click?platfrom=(" +_this.query.site +")";
      _this.addTrackingLog(logstr);
      // 调用 Vuex
      _this.$store.commit('setCurrentQuery', _this.query);
      _this.searchData();
    },
    chanageSku(){
      var _this = this;
      //埋点位数不够13位则不存
      if(_this.query.skuNumber && _this.query.skuNumber.length >12){
        var logstr = "history_sku_click?sku=(" +query.skuNumber +")";
        // 调用 Vuex
        _this.$store.commit('setCurrentQuery', _this.query);
        _this.addTrackingLog(logstr);
      }
      
      _this.searchData();
    },
    clear:function(event, propertyStr) {
      var _this = this;
      if(propertyStr == 'platform'){
        _this.siteList = [];
        //埋点
        var logstr = "history_platfrom_clear_click";
        _this.addTrackingLog(logstr);
        
      }else{
        //埋点
        var logstr = "history_site_clear_click";
        _this.addTrackingLog(logstr);
        
      }
      _this.query[propertyStr] = "";
      // 调用 Vuex
      _this.$store.commit('setCurrentQuery', _this.query);
      _this.searchData();
    },

    searchData: function() {
      if(!this.ifEditReturn){
        this.pageDomain.pageIndex = 1;
        this.pageDomain.recordCount = 0;
      }else{
        // 如果父组件传过来的参数为 true，从 store 中获取当前页和query
        this.pageDomain.pageIndex = this.$store.state.currentPage;
        this.query = this.$store.state.currentQuery;
      }
      
      this.getDataList();
    },

    getDataList: function() {
      var _this = this;
      var dataMap = {
        "query": _this.query,
        "pageDomain": _this.pageDomain
      };
      _this.searchLoading = true;
      _this.$axios.post('/productPublish/xaz/selectByPage', dataMap).then((result) => {
        if(result.flag == true) {
          // debugger;
          // _this.dataList = result.dataList;
          _this.$set(this, 'dataList', result.dataList);
          _this.pageDomain = result.pageDomain;
        }else {
          _this.$message({ showClose: true, message: result.msg, type: 'error'  });
        }
        _this.searchLoading = false;
      });
      setTimeout(function() {
        _this.searchLoading = false;
      }, 10 * 1000);
    },

    toggleSelection(id) {
      var _this = this;
      const index = _this.selectedItemIdList.indexOf(id);
      if (index === -1) {
        _this.selectedItemIdList.push(id);
      } else {
        _this.selectedItemIdList.splice(index, 1);
      }
    },

    downloadByBatch(){
      var _this = this;
      if (_this.selectedItemIdList.length === 0) {
        return _this.$message.error('Please select the data to download!');
      }
      _this.$axios.post('/productPublish/xaz/downloadZip', _this.selectedItemIdList,{responseType:'blob'}).then(async result => {
        if (result.response.headers.flag ==='false' ) {
          const msg = await result.text();
          _this.$message.error(msg);
          return;
        }
        var dateStr = _this.formatDateAll(new Date());
        var fileName = "publishHistory_" + dateStr + ".zip"
        DownloadUtils.download(result,fileName);
      }).catch(() =>{

      });
    },

    downloadByRow(row){
      var _this = this;
      // debugger;
      //埋点
      var logstr = "history_download_confirm_click";
      _this.addTrackingLog(logstr);
      const idsArray = [];
      idsArray.push(row.id);
      _this.$axios.post('/productPublish/xaz/downloadZip', idsArray,{responseType:'blob'}).then(async result => {
        if (result.response.headers.flag ==='false' ) {
          const msg = await result.text();
          _this.$message.error(msg);
          return;
        }
        var date = new Date();
        var dateStr = _this.formatDateAll(date);
        var fileName = row.platform + "_" + row.site + "_" + dateStr + ".zip"
        DownloadUtils.download(result,fileName);
      }).catch(() =>{

      });
    },    

    deleteByBatch(){
      var _this = this;
      if (_this.selectedItemIdList.length === 0) {
        return _this.$message.error('Please select the data to be removed!');
      }
      _this.$confirm('Are you sure to delete this Data?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //埋点
        var logstr = "history_delete_confirm_click";
        _this.addTrackingLog(logstr);
        _this.$axios.post('/productPublish/xaz/deleteByIds', _this.selectedItemIdList).then(response => {
          _this.$message.success('success');
          _this.searchData();
        }, err => {
          _this.$message.error(err.response.data.msg);
        })
        .finally(() => {
          window.location.reload(); // 刷新页面
        });
      }).catch(() => {
        _this.$message({
          type: 'info',
          message: 'Cancelled'
        }); 
      });

      _this.$nextTick(() => {
        const confirmBtn = document.querySelector('.el-message-box__btns .el-button--primary');
        const cancelBtn = document.querySelector('.el-message-box__btns .el-button--default');

        // ✅ 设置按钮基础样式（边框加粗 + 过渡动画）
        [confirmBtn, cancelBtn].forEach((btn) => {
          btn.style.borderWidth = '2px';  // **边框加粗**
          btn.style.transition = 'all 0.2s ease-in-out';
        });

        // ✅ 确认按钮（黑色背景 + 白色文字）
        confirmBtn.style.backgroundColor = 'black';
        confirmBtn.style.borderColor = 'black';
        confirmBtn.style.color = 'white';

        // ✅ 取消按钮（白色背景 + 黑色文字 + 黑色边框）
        cancelBtn.style.backgroundColor = 'white';
        cancelBtn.style.borderColor = 'black';
        cancelBtn.style.color = 'black';

        // ✅ 添加鼠标按下（mousedown）时的灰色阴影
        [confirmBtn, cancelBtn].forEach((btn) => {
          btn.addEventListener('mousedown', () => {
            btn.style.boxShadow = '0 4px 12px rgba(128, 128, 128, 0.5)';  // **灰色阴影**
          });
          btn.addEventListener('mouseup', () => {
            btn.style.boxShadow = 'none'; // **松开时阴影消失**
          });
        });
      });

    },

    deleteByRow(row){
      var _this = this;
      _this.$confirm('Are you sure to delete this Data?', 'Warning', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        //埋点
        var logstr = "history_delete_confirm_click";
        _this.addTrackingLog(logstr);
        const idsArray = [];
        idsArray.push(row.id);
        this.$axios.post('/productPublish/xaz/deleteByIds', idsArray).then(response => {
          this.$message.success('success');
          this.searchData();
        }, err => {
          this.$message.error(err.response.data.msg);
        })
        .finally(() => {
          window.location.reload(); // 刷新页面
        });
      }).catch(() => {
        _this.$message({
          type: 'info',
          message: 'Cancelled'
        }); 
      });

      _this.$nextTick(() => {
        const confirmBtn = document.querySelector('.el-message-box__btns .el-button--primary');
        const cancelBtn = document.querySelector('.el-message-box__btns .el-button--default');

        // ✅ 设置按钮基础样式（边框加粗 + 过渡动画）
        [confirmBtn, cancelBtn].forEach((btn) => {
          btn.style.borderWidth = '2px';  // **边框加粗**
          btn.style.transition = 'all 0.2s ease-in-out';
        });

        // ✅ 确认按钮（黑色背景 + 白色文字）
        confirmBtn.style.backgroundColor = 'black';
        confirmBtn.style.borderColor = 'black';
        confirmBtn.style.color = 'white';

        // ✅ 取消按钮（白色背景 + 黑色文字 + 黑色边框）
        cancelBtn.style.backgroundColor = 'white';
        cancelBtn.style.borderColor = 'black';
        cancelBtn.style.color = 'black';

        // ✅ 添加鼠标按下（mousedown）时的灰色阴影
        [confirmBtn, cancelBtn].forEach((btn) => {
          btn.addEventListener('mousedown', () => {
            btn.style.boxShadow = '0 4px 12px rgba(128, 128, 128, 0.5)';  // **灰色阴影**
          });
          btn.addEventListener('mouseup', () => {
            btn.style.boxShadow = 'none'; // **松开时阴影消失**
          });
        });
      });

    },    

    handleSizeChange: function(val) {
      //埋点
      var logstr = "history_show_click?page=("+val+")";
      this.addTrackingLog(logstr);
      this.pageDomain.pageSize = val;
      this.getDataList();
    },
    // handleSelectionChange: function(val) {
    //   this.multipleSelection = val;
    // },
    handleCurrentChange: function(newPage) {
      //埋点
      var logstr = "history_turn_page_click";
      this.addTrackingLog(logstr);
      // 调用 Vuex
      this.$store.commit('setCurrentPage', newPage);
      this.getDataList();
    },
    handlePrevClick() {
      console.log('上一页被点击');
      //埋点
      var logstr = "history_turn_page_click";
      this.addTrackingLog(logstr);
    },
    handleNextClick() {
      console.log('下一页被点击');
      //埋点
      var logstr = "history_turn_page_click";
      this.addTrackingLog(logstr);
    },
    queryInit: function() {
      var  _this= this;
      var dataMap = {
        query:_this.query
      };
      _this.$axios.post('/platformGoods/xaz/queryInit', dataMap
      ).then((result) => {
        if(result.flag == true) {
          _this.accountList = result.accountList;
          _this.siteList = result.siteList;
          _this.statusList = result.statusList;
          _this.platformList = result.platformList;
        }
      });
    },

    dateFormat: function(row, column, cellValue) {
      if (cellValue != null) {
        var date = new Date(cellValue);
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate()
        );
      } else {
        return "";
      }
    },

    formatDateAll(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    },

    formatDate(date) {
      var newDate = new Date(date);
      if (isNaN(newDate)) {
        return "Invalid Date"; // 避免无效日期时报错
      }

      // 获取月份名称
      const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      const monthName = months[newDate.getMonth()]; // 获取月份索引并转换为名称

      return `${monthName} ${newDate.getDate()}, ${newDate.getFullYear()}`;
    },
    
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    
    handleJumpToPage() {
      if (this.jumpToPage >= 1 && this.jumpToPage <= this.totalPages) {
        this.currentPage = this.jumpToPage;
      } else {
        // Reset to valid value if out of range
        this.jumpToPage = this.currentPage;
        this.$message.warning('Please enter a valid page number');
      }
    },
    
    toggleDropdown(id) {
      if (this.openDropdown === id) {
        this.openDropdown = null;
      } else {
        this.openDropdown = id;
      }
    },

    closeAllDropdowns() {
      this.openDropdown = null;
    },

    editData(row) {
      //埋点
      var logstr = "history_edit_confirm_click";
      this.addTrackingLog(logstr);
      this.$router.push({ name: 'PublishHistoryEdit', query: { id: row.id } });
    },
    //埋点
    addTrackingLog(eid){
      this.$axios.get('/productPublish/xaz/addTrackingLog', {
        params: {
          e: eid,
        }
      });
    },

    isLastItem(productId) {
      const index = this.dataList.findIndex(p => p.id === productId);
      return index >= this.dataList.length - 1; // 判断是否是最后一行
    },

    handleCommand(command, product) {
      switch (command) {
        case "edit":
          this.editData(product);
          break;
        case "download":
          this.downloadByRow(product);
          break;
        case "remove":
          this.deleteByRow(product);
          break;
        default:
          console.warn("Unknown command:", command);
      }
    },

  }
}
</script>

<style lang="less" scoped deep>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

.app-layout {
  // display: flex;
  // min-height: 100vh;
  width: 98%;
  margin: 0px auto;
  overflow-y: auto; // 内容超出时显示滚动条
}

/* Main Content Styles */
.main-content {
  flex: 1;
  // margin-left: 220px;
  padding: 30px;
  background-color: #f0f0f0; /* Match the active sidebar button background */
}

.history-page {
  // max-width: 1200px;
  margin: 0 auto;
  flex: 1;
  padding: 15px;
  overflow-y: auto; // 内容超出时显示滚动条
}

h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.content_header{
  background-color: #000;
  margin: 0px 0px;
  padding: 30px 30px;
  color: #fff;
  border-radius: 8px;
}

/* Filters */
.filters-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.filters {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
}

.filter-select {
  width: 150px;
}

.search-input {
  width: 200px;
}

.action-buttons {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.action-buttons .el-button {
  background-color: white !important; /* 白色背景 */
  color: black !important; /* 黑色文字 */
  border: 1px solid #ddd !important; /* 灰色边框 */
}

.action-buttons .el-button i {
  color: black !important; /* 图标也变黑 */
}

.action-buttons .el-button:hover {
  background-color: #f5f5f5 !important; /* 浅灰色背景 */
  border-color: #bbb !important; /* 加深边框颜色 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 添加轻微阴影 */
}

/* Table Styles */
.el-table {
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.image-col {
  width: 80px;
  height: 80px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-col img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.details-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail-row {
  display: flex;
  gap: 30px;
}

.detail-group {
  min-width: 120px;
}

.detail-label {
  font-size: 12px;
  color: #999;
  margin-bottom: 3px;
}

.detail-value {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.date-col {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  margin-right: 20px;
  min-width: 120px;
}

.date-icon {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  color: #666;
}

.status-badge {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 3px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.status-badge.complete {
  background-color: #e6f7e6;
  color: #2e7d32;
}

.status-badge.incomplete {
  background-color: #fff8e1;
  color: #ff8f00;
}

.status-badge.new {
  background-color: #f5f5f5;
  color: #616161;
}

.status-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.status-badge.complete .status-dot {
  background-color: #2e7d32;
}

.status-badge.incomplete .status-dot {
  background-color: #ff8f00;
}

.status-badge.new .status-dot {
  background-color: #616161;
}

.el-dropdown-link {
  cursor: pointer;
}

/* Pagination (Matching the image) */
.pagination {
  display: flex;
  align-items: center;
  margin-top: 30px;
  gap: 10px;
  flex-wrap: wrap;
}

.pagination-info {
  font-size: 14px;
  color: #666;
}

.pagination-btn {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.prev-btn, .next-btn {
  background-color: #000;
  color: white;
}

.current-page {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  color: #0066ff;
}

.go-to-page {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #666;
}

.jump-input {
  width: 50px;
  padding: 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

.items-per-page {
  margin-left: auto;
}

.per-page-select {
  padding: 6px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer;
  min-width: 100px;
}

.empty-state {
  padding: 30px;
  text-align: center;
  background-color: white;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  color: #666;
}

/* Dialog Styles */
.confirm-dialog .el-dialog__header {
  padding: 15px 20px;
  border-bottom: 1px solid #eaeaea;
}

.confirm-dialog .el-dialog__body {
  padding: 20px;
}

.dialog-content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.warning-icon {
  font-size: 24px;
  color: #f39c12;
}

/* Product List */
.product-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.product-item {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 15px;
  transition: all 0.2s ease;
}

/* Hover effect for product items */
.product-item:hover {
  background-color: #f8f9ff;
  border-color: #d0d7f7;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transform: translateY(-1px);
}

.checkbox-col {
  width: 30px;
  display: flex;
  justify-content: center;
}

.actions-col {
  min-width: 40px;
  position: relative;
}

/* Dropdown Menu */
.dropdown-container {
  position: relative;
}

.more-btn {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  z-index: 10;
}

.more-btn:hover {
  background-color: #f5f5f5 !important; /* 浅灰色背景 */
  border-color: #bbb !important; /* 加深边框颜色 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 添加轻微阴影 */
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 5px;
  background-color: white;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  min-width: 150px;
}

/* 最后一行向上展开 */
.dropdown-menu.dropdown-up {
  top: auto;
  bottom: 100%;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #333;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.dropdown-item:not(:last-child) {
  border-bottom: 1px solid #eaeaea;
}

.pageWrapper{
  position: relative;
  .page-select-total{
    width: 220px;
    display: block;
    position: absolute;
    left: 0px;
    top: 10px;
    z-index: 5;
    font-size: 13px;
    font-weight: 500;
  }
  /deep/ .el-select {
    width: 110px !important;
    height: 28px;
    line-height: 28px;
  }
  /deep/ .el-select .el-input{
    width: 110px !important;
    height: 28px;
    line-height: 28px;
  }
  /deep/ .el-select .el-input__inner {
      height: 28px;
      line-height: 28px;                 

  }
  /deep/  .el-input {
      width: 80px !important;
      height: 28px;
      line-height: 28px;
  }
  /deep/ .el-input .el-input__inner{
      height: 28px;
      line-height: 28px;
  }
  /* pagination */
  /deep/ .el-pagination .el-pager  {
    color: #909399;
  }
  /deep/ .el-pagination {
    text-align: center;
    .el-pager {
      li:hover {
        color: #000;
      }
    }
    .btn-next:hover,
    .btn-prev:hover {
      color: #000;
      i:hover {
        color: #000;
      }
    }
    .el-pagination__sizes {
      .el-select {
        .el-input {
          .el-input__inner:hover {
            border-color: #000;
          }
        }
        .is-focus {
          .el-input__inner {
            border-color: #000;
          }
        }
        .el-input__inner:focus {
          border-color: #000;
        }
      }
    }
    .el-pagination__jump {
      .el-pagination__editor {
        .el-input__inner:focus {
          border-color: #000;
        }
      }
    }
    .btn-prev,.btn-next{
      background-color: #909399;
      color: #fff;
    }
    .btn-prev:hover,.btn-next:hover{
      background-color: #000;
      color: #fff;
    }
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .sidebar {
    width: 60px;
  }
  
  .sidebar .logo, .nav-item span {
    display: none;
  }
  
  .main-content {
    margin-left: 60px;
  }
  
  .filters {
    flex-direction: column;
    align-items: stretch;
  }
  
  .action-buttons {
    margin-left: 0;
    width: 100%;
    justify-content: space-between;
  }
  
  .product-item {
    flex-wrap: wrap;
  }
  
  .details-col {
    width: 100%;
    margin-top: 15px;
  }
  
  .date-col, .actions-col {
    width: 100%;
    align-items: flex-start;
    margin-top: 15px;
  }
  
  .pagination {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .items-per-page {
    margin-left: 0;
  }
  
  .dropdown-menu {
    right: 0;
    left: auto;
  }
  
  .dialog-box {
    width: 90%;
    max-width: 400px;
  }

  .filter-select, .search-input {
    width: 100%;
  }
 
}

</style>