<template>
  <div class="content-container">
    <!-- Main Content -->
    <el-container class="main-container">
      <el-main>
        <!-- Banner -->
        <div class="banner">
          <h2 class="banner-title">Selling Products</h2>
          <p class="banner-subtitle">Manage and monitor your product listings across all platforms</p>
        </div>

        <!-- Filters -->
        <div class="filters-container">
          <div class="filters-left">
            <el-select v-model="query.threeOrganization" class="platform-select" popper-class="platform-select-dropdown" placeholder="Organization" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.threeOrganization')})">
              <el-option
                v-for="item in threeOrganizationList"
                :key="item.aid"
                :label="item.aname"
                :value="item">
              </el-option>
            </el-select>

            <el-select v-model="query.thirdAccount" class="" placeholder="Shop" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.thirdAccount')})">
              <el-option
                v-for="item in accountList"
                :key="item.aid"
                :label="item.aname + '(' + item.thirdPlatform.aname + ')'"
                :value="item">
              </el-option>
            </el-select>

            <el-select v-model="query.thirdPlatform" class="platform-select" popper-class="platform-select-dropdown" placeholder="Platform" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.thirdPlatform')})">
              <el-option
                v-for="item in platformList"
                :key="item.aid"
                :label="item.aname"
                :value="item">
              </el-option>
            </el-select>

            <el-select v-model="query.site" class="site-select" popper-class="site-select-dropdown" placeholder="Site" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.site')})">
              <el-option
                v-for="item in siteList"
                :key="item.aid"
                :label="item.aisocode"
                :value="item">
              </el-option>
            </el-select>

            <el-select v-model="query.astatus" class="status-select" popper-class="status-select-dropdown" placeholder="Status" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.astatus')})">
              <el-option
                v-for="item in statusList"
                :key="item.aid"
                :label="item.aname"
                :value="item">
              </el-option>
            </el-select>

            <div class="search-container">
              <el-select v-model="query.filterBy" class="filterby-select" popper-class="filterby-select-dropdown" @change="changeFilterBy" placeholder="Search by">
                <el-option
                  v-for="(item, index) in filterByList"
                  :key="index"
                  :label="item.title"
                  :value="item.value">
                </el-option>
              </el-select>
              <!-- <el-input
                v-model="searchTerm"
                :placeholder="getSearchPlaceholder()"
                prefix-icon="el-icon-search">
              </el-input> -->
              <el-input placeholder="Search" v-model="query.search"  @input="changehSearchData">
                <i slot="prefix" class="el-icon-search" @click="searchData()"></i>
              </el-input>
            </div>
          </div>

          <div class="filters-right">
            
            <el-button size="small" @click="columnManagerOpen = true">
              <i class="el-icon-s-grid"></i> Columns
            </el-button>
           
            <el-button size="small" @click="exportExcel" class="white-button">
              <i class="el-icon-download"></i> Export
            </el-button>
          </div>
        </div>

        <!-- Table -->
        <el-card class="table-card">
          <el-table
            ref="dataTable"
            :data="dataList"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            @sort-change="handleSortChange"
            v-loading="isLoading"
            height="580"
            :key="drawKey"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
             <template v-for="column in visibleColumns">
                <el-table-column v-if="column.name === 'Sku' && column.show" class-name="text-gray-700" prop="merchandise.anumber" label="Sku" :width="column.awidth"></el-table-column>
                <el-table-column v-else-if="column.name === 'Sku Name' && column.show" class-name="text-gray-700" prop="merchandise.aname" label="Sku Name" :width="column.awidth"></el-table-column>
                <el-table-column v-else-if="column.name === 'Main Image' && column.show" label="Main Image" :width="column.awidth">
                <template slot-scope="slotscope">
                  <div class="product-image">
                    <el-image
                      v-if="slotscope.row.imageUrl !=null && slotscope.row.imageUrl != ''"
                      :src="slotscope.row.imageUrl"
                      class="product-list-image"
                      fit="contain"
                      :preview-src-list="[slotscope.row.imageUrl]"
                    >
                    </el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-else-if="column.name === 'Shop' && column.show" class-name="text-gray-700" prop="thirdAccount.aname" label="Shop" :width="column.awidth"></el-table-column>
              <el-table-column v-else-if="column.name === 'Platform' && column.show" class-name="text-gray-700" prop="thirdPlatform.aname" label="Platform" :width="column.awidth"></el-table-column>
              <el-table-column v-else-if="column.name === 'Site' && column.show" class-name="text-gray-700" prop="site.aisocode" label="Site" :width="column.awidth"></el-table-column>
              <el-table-column v-else-if="column.name === 'Platform Sku ID' && column.show" prop="aplatformSkuId1" label="Platform Sku ID" :width="column.awidth">
                <template slot-scope="slotscope">
                  <a v-if="slotscope.row.aproductUrl" :href="slotscope.row.aproductUrl" target="_blank" rel="noopener noreferrer"><span>{{slotscope.row.aplatformSkuId1}}</span></a>
                  <span v-else>{{slotscope.row.aplatformSkuId1}}</span>
                </template>
              </el-table-column>
              <el-table-column v-else-if="column.name === 'Platform Sku Name' && column.show" prop="platformSku" label="Platform Sku Name" :width="column.awidth">
                <template slot-scope="slotscope">
                  <a v-if="slotscope.row.aproductUrl" :href="slotscope.row.aproductUrl" target="_blank" rel="noopener noreferrer"><span>{{slotscope.row.platformSku}}</span></a>
                  <span v-else>{{slotscope.row.platformSku}}</span>
                </template>
              </el-table-column>
              <el-table-column v-else-if="column.name === 'Status' && column.show" class-name="status-cell" prop="astatus.aname" label="Status" :width="column.awidth">
                <template slot-scope="scope">
                  <el-tag :class="[['Active', 'DISCOVERABLE', 'Published'].includes(scope.row.astatus?.aname) ? 'active-status' : 'inactive-status']" :type="['Active', 'DISCOVERABLE', 'Published'].includes(scope.row.astatus?.aname) ? 'success' : 'info'" size="small" v-if="scope.row.astatus">
                    {{ scope.row.astatus?.aname }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column v-else-if="column.name === 'Price' && column.show" class-name="text-gray-700" prop="price" label="Price" :width="column.awidth" sortable>
                <template slot-scope="scope">
                  {{ scope.row.price && scope.row.price.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column v-else-if="column.name === 'Currency' && column.show" class-name="text-gray-500" prop="acurrency.aisocode" label="Currency" :width="column.awidth"></el-table-column>
              <el-table-column v-else-if="column.name === 'Sales Qty 7' && column.show" prop="asevenDaySales" label="Sales Qty 7" :width="column.awidth" sortable>
                <template slot-scope="scope">
                  <div class="text-blue-600 hover-text-blue-800">
                    {{ scope.row.asevenDaySales || 0 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-else-if="column.name === 'Sales Qty 30' && column.show" prop="athirtyDaySales" label="Sales Qty 30" :width="column.awidth" sortable>
                <template slot-scope="scope">
                  <div class="text-blue-600 hover-text-blue-800">
                    {{ scope.row.athirtyDaySales || 0 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-else-if="column.name === 'Sales Qty 90' && column.show" prop="aninetyDaySales" label="Sales Qty 90" :width="column.awidth" sortable>
                <template slot-scope="scope">
                  <div class="text-blue-600 hover-text-blue-800">
                    {{ scope.row.aninetyDaySales || 0 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-else-if="column.name === 'Category' && column.show" prop="categoryBread" label="Category" :width="column.awidth">
                <template slot-scope="scope">
                  <el-popover
                    placement="top-start"
                    width="300"
                    trigger="hover"
                    v-if="scope.row.categoryBread != undefined && scope.row.categoryBread !=''"
                    :content="scope.row.categoryBread"
                  >
                    <span slot="reference">{{scope.row.categoryBread.substr(0,25)+'...'}}</span>
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column v-else-if="column.name === 'Title' && column.show" prop="atitle" label="Title" :width="column.awidth">
                <template slot-scope="scope">
                  <el-tooltip :content="scope.row.atitle" placement="top">
                    <span class="truncate-text">{{ scope.row.atitle }}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column v-else-if="column.name === 'Description' && column.show" prop="adescription" label="Description" :width="column.awidth">
                <template slot-scope="scope">
                  <el-tooltip :content="scope.row.adescription" placement="top">
                    <span class="truncate-text">{{ scope.row.adescription }}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column v-else-if="column.name === 'Create Time' && column.show" prop="openDate" label="Create Time"  :width="column.awidth"  :formatter="dateFormat"></el-table-column>
              <el-table-column v-else-if="column.name === 'Organization' && column.show" prop="threeOrganization.aname" label="Organization" :width="column.awidth"></el-table-column>
            </template>
            <el-table-column  label="" width="150">
              <template slot-scope="scope">
                <div class="dropdown-container">
                  <button @click.stop="toggleDropdown(scope.row.aid)" class="more-btn">
                    ...
                  </button>
                  <div v-if="openDropdown === scope.row.aid" class="dropdown-menu">
                    <button @click.stop="analyticsData(scope.row)" class="dropdown-item">
                      <i class="el-icon-picture-outline-round"></i>
                      AI 
                    </button>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!-- 空数据状态插槽 -->
            <!--
            <template v-slot:empty>
              <div v-if="isLoading" style="text-align: center; padding: 20px;">
                <el-spinner class="loading" />
                <span>Loading data...</span>
              </div>
              <div v-else style="text-align: center; padding: 20px;">
                <span>No data available</span>
              </div>
            </template>
            -->
          </el-table>

          <!-- Pagination -->
          <div class="pagination-container">
            <span class="pagination-info">
              Selected <strong>{{multipleSelection.length}}</strong> of <strong>{{pageDomain.pageSize}}</strong> products
            </span>
            <el-pagination
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              @prev-click="handlePrevClick"
              @next-click="handleNextClick"
              :current-page.sync="pageDomain.pageIndex"
              :page-size="pageDomain.pageSize"
              :page-sizes="[10, 20, 50, 100]"
              layout="total,prev, pager, next, jumper,sizes"
              :total="pageDomain.recordCount"
            >
            </el-pagination>
          </div>
        </el-card>
      </el-main>
    </el-container>

    <!-- Column Manager Dialog -->
    <el-dialog
      title="Manage Columns"
      :visible.sync="columnManagerOpen"
      width="500px"
      top="8vh" 
    >
      <div class="column-manager-content">
        <div class="dialogTree">
          <div class="dialogTreeTitle">
            <span class="spanDiv">Label</span>
            <span class="spanDiv">Visible</span>
            <!-- <span class="spanDiv">冻结</span>
            <span class="spanDiv">置顶</span>
            <span class="spanDiv">宽度</span> -->
          </div>
          <el-tree draggable :data="columnMetaDataList" :props="treeProps" node-key = "name" ref="unfixedtree" :allow-drop="allowDrop" @node-drop="handleDrop">
            <div class="tree-table-setting" slot-scope="{ node, data }">
              <span :id="data.name"><i class="el-icon-menu"></i>&nbsp;{{ node.label }}</span>
              <!-- <el-input type="number" v-model="data.awidth"></el-input>
              <el-button size="mini" type="primary" plain @click="unfixedtop(node,data)">置顶 </el-button>
              <el-button size="mini" type="primary" plain @click="freeze(node,data)">冻结 </el-button> -->
              <el-switch  v-model="data.show"> </el-switch>
            </div>
          </el-tree>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetDefaultColumns">Reset to Default</el-button>
        <el-button type="primary" @click="savePageCustomization" :loading="savePageCustomizationLoading">Apply Changes</el-button>
      </span>
    </el-dialog>


    <!-- Sales Chart Dialog -->
    <el-dialog
      title="销量趋势分析"
      :visible.sync="dialogOpen"
      width="800px"
    >
      <div class="sales-dialog-header">
        <div class="sales-dialog-filters">
          <el-select v-model="selectedPlatformChart" placeholder="Platform">
            <el-option label="All Platforms" value="all"></el-option>
            <el-option label="Amazon" value="amazon"></el-option>
            <el-option label="Walmart" value="walmart"></el-option>
            <el-option label="Shopify" value="shopify"></el-option>
          </el-select>

          <div class="search-input">
            <el-input
              v-model="chartSearchTerm"
              placeholder="Search SKU/ASIN..."
              prefix-icon="el-icon-search">
            </el-input>
          </div>

          <el-select v-model="selectedDateRange" placeholder="Time Range">
            <el-option label="Last 7 Days" value="7d"></el-option>
            <el-option label="Last 14 Days" value="14d"></el-option>
            <el-option label="Last 30 Days" value="30d"></el-option>
            <el-option label="Last 90 Days" value="90d"></el-option>
          </el-select>
        </div>

        <el-card v-if="currentProduct" class="product-info-card">
          <div class="product-info">
            <div class="product-info-item">
              <i class="el-icon-shopping-cart-2"></i>
              <span>{{ currentProduct.platform }}</span>
            </div>
            <div class="product-info-item">
              <i class="el-icon-location"></i>
              <span>{{ currentProduct.site }}</span>
            </div>
            <el-tag v-if="currentProduct.sku" size="small">SKU: {{ currentProduct.sku }}</el-tag>
            <el-tag v-if="currentProduct.asin" size="small">ASIN: {{ currentProduct.asin }}</el-tag>
          </div>
        </el-card>
      </div>

      <div class="product-tags">
        <el-tag
          v-for="product in chartProducts"
          :key="product.key"
          :type="selectedChartProducts.includes(product.key) ? '' : 'info'"
          effect="plain"
          @click="toggleProductSelection(product.key)"
          class="product-tag"
          :style="getProductTagStyle(product)"
        >
          {{ product.key }}
          <span v-if="selectedChartProducts.includes(product.key)" class="product-tag-sales">
            {{ Math.round(totalSales / salesData[selectedTimeRange].length) }}
          </span>
        </el-tag>
      </div>

      <div class="chart-container">
        <!-- This would be replaced with an actual chart component like ECharts -->
        <div class="chart-placeholder">
          <p>Sales chart would be rendered here using ECharts</p>
          <p>Selected products: {{ selectedChartProducts.join(', ') }}</p>
          <p>Time range: {{ selectedTimeRange }} days</p>
          <p>Date range: {{ selectedDateRange }}</p>
        </div>
      </div>

      <el-card class="ai-analysis-card" :body-style="{ padding: '20px' }">
        <div slot="header" class="ai-analysis-header">
          <i class="el-icon-magic-stick"></i>
          <span>AI 分析洞察</span>
        </div>
        <p class="ai-analysis-content">{{ aiAnalysis }}</p>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import * as DownloadUtils from '@/utils/DownloadUtils.js';
import { nextTick } from 'vue';

export default {
  name: 'AiProductListComp2',
  data() {
    return {
      query:{
        filterBy: 'platformSkuId',
        search: null,
        sortBy: null,
        order: null,
      },
      pageDomain: {},
      multipleSelection: [],
      dataList: [],
      accountList: [],
      siteList: [],
      statusList: [],
      platformList: [],
      threeOrganizationList: [],
      isLoading: true,
      filterByList:[
        {
          title:'Sku',
          value:"skuNo",
        },
        {
          title: 'Sku Name',
          value: 'skuName',
        },
        {
          title:'Platform Sku ID',
          value:"platformSkuId",
        },
        {
          title:'Platform Sku Name',
          value:"platformSkuName",
        }
      ],

      // Search and filters
      searchTerm: '',
      searchField: 'sku',
      selectedPlatform: 'all',
      selectedSite: 'all',
      selectedShop: 'all',

      // Table pagination
      currentPage: 1,

      // Column management
      columnManagerOpen: false,
      entryColumnMeta: [],//接口返回默认json
      columnMetaData:[],  //table 遍历渲染
      columnMetaDataList: [], //页面自定义弹框编辑
      drawKey: 0,
      treeProps:{
        label:"name",
        children:"children"
      },
      savePageCustomizationLoading: false,
      columns: [
        { id: "select", name: "", visible: true },
        { id: "sku", name: "Sku", visible: true },
        { id: "skuName", name: "Sku Name", visible: true },
        { id: "image", name: "Main Image", visible: true },
        { id: "platform", name: "Platform", visible: true },
        { id: "site", name: "Site", visible: true },
        { id: "platformSkuId", name: "Platform Sku ID", visible: true },
        { id: "platformSkuName", name: "Platform Sku Name", visible: true },
        { id: "status", name: "Status", visible: true },
        { id: "price", name: "Price", visible: true },
        { id: "currency", name: "Currency", visible: true },
        { id: "salesQty7", name: "Sales Qty 7", visible: true },
        { id: "salesQty30", name: "Sales Qty 30", visible: true },
        { id: "salesQty90", name: "Sales Qty 90", visible: true },
        { id: "category", name: "Category", visible: true },
        { id: "title", name: "Title", visible: true },
        { id: "description", name: "Description", visible: true },
        { id: "actions", name: "Actions", visible: true },
      ],

      // Selected rows
      selectedRows: [],

      // Sales chart dialog
      dialogOpen: false,
      selectedProduct: '',
      selectedTimeRange: '30',
      selectedDateRange: '7d',
      selectedPlatformChart: 'all',
      chartSearchTerm: '',
      selectedChartProducts: [],
      totalSales: 0,
      salesTrend: 'stable',
      openDropdown: null,
      // Search fields options
      searchFields: [
        { value: "sku", label: "Sku" },
        { value: "skuName", label: "Sku Name" },
        { value: "platformSkuId", label: "Platform Sku ID" },
        { value: "platformSkuName", label: "Platform Sku Name" },
        { value: "title", label: "Title" },
        { value: "category", label: "Category" },
      ],

      // Product data
      products: [
        {
          sku: "1",
          skuName: "HLVC-TRAY-BL",
          image: "/placeholder.svg?height=50&width=50",
          platform: "Amazon",
          site: "DE",
          platformSkuId: "B0DYNSH3MK",
          platformSkuName: "HLVC BASELINE BL K-TRAY",
          status: "Active",
          price: 49.99,
          currency: "EUR",
          salesQty7: 125,
          salesQty30: 3250,
          salesQty90: 9875,
          category: "Home & Kitchen",
          title: "HLVC Baseline Black Kitchen Tray Organizer",
          description: "Premium kitchen tray organizer with durable construction and elegant design.",
        },
        {
          sku: "2",
          skuName: "HLVC-FOLD-BL",
          image: "/placeholder.svg?height=50&width=50",
          platform: "Amazon",
          site: "DE",
          platformSkuId: "B0DYNWLKW7",
          platformSkuName: "HLVC BASELINE BL K-FOLD",
          status: "Active",
          price: 49.99,
          currency: "EUR",
          salesQty7: 98,
          salesQty30: 2860,
          salesQty90: 8540,
          category: "Home & Kitchen",
          title: "HLVC Baseline Black Kitchen Fold Organizer",
          description: "Foldable kitchen organizer with space-saving design and premium materials.",
        },
        {
          sku: "3",
          skuName: "HLVC-END-BL",
          image: "/placeholder.svg?height=50&width=50",
          platform: "Amazon",
          site: "DE",
          platformSkuId: "B0DYNW7N74",
          platformSkuName: "HLVC BASELINE BL K-END",
          status: "Active",
          price: 49.99,
          currency: "EUR",
          salesQty7: 75,
          salesQty30: 2450,
          salesQty90: 7280,
          category: "Home & Kitchen",
          title: "HLVC Baseline Black Kitchen End Piece",
          description: "End piece for HLVC kitchen organization system with seamless integration.",
        },
        {
          sku: "4",
          skuName: "HLVC-FOLD-BL-GB",
          image: "/placeholder.svg?height=50&width=50",
          platform: "Amazon",
          site: "GB",
          platformSkuId: "B0DYNWLKW7",
          platformSkuName: "HLVC BASELINE BL K-FOLD",
          status: "Active",
          price: 44.99,
          currency: "GBP",
          salesQty7: 89,
          salesQty30: 2670,
          salesQty90: 7950,
          category: "Home & Kitchen",
          title: "HLVC Baseline Black Kitchen Fold Organizer",
          description: "Foldable kitchen organizer with space-saving design and premium materials.",
        },
        {
          sku: "5",
          skuName: "CARN-BW-FBA",
          image: "/placeholder.svg?height=50&width=50",
          platform: "Amazon",
          site: "GB",
          platformSkuId: "B0DW8YJNQX",
          platformSkuName: "CARNATION BLACK WHITE FBA",
          status: "Inactive",
          price: 39.99,
          currency: "GBP",
          salesQty7: 0,
          salesQty30: 1580,
          salesQty90: 4560,
          category: "Home Decor",
          title: "Carnation Black & White Decorative Piece",
          description: "Elegant black and white decorative piece for modern home interiors.",
        },
      ],

      // Chart products
      chartProducts: [
        { key: "HLVC BASELINE BL K-TRAY (DE)", color: "#8884d8" },
        { key: "HLVC BASELINE BL K-FOLD (DE)", color: "#82ca9d" },
        { key: "HLVC BASELINE BL K-END (DE)", color: "#ffc658" },
        { key: "HLVC BASELINE BL K-FOLD (GB)", color: "#82ca9d" },
        { key: "CARNATION BLACK WHITE (GB)", color: "#ff7c43" },
        { key: "All Products", color: "#0088fe" }
      ],

      // Mock sales data
      salesData: {
        '7': [],
        '30': [],
        '90': []
      },

      // AI analysis
      aiAnalysis: "基于全平台5个SKU的历史数据分析，销量趋势呈现上升态势，环比上升8.5%。近期日均销量387件，预计未来30天销量将持续增长。主要影响因素：\n1. 市场需求增加\n2. 广告投放效果良好\n3. 好评率持续提升"
    }
  },
  computed: {
    // Get current product info for the chart dialog
    currentProduct() {
      if (this.selectedProduct === "All Products") {
        return { platform: "all", site: "all" };
      }

      for (const product of this.products) {
        if (product.platformSkuName === this.selectedProduct.split(" (")[0]) {
          return {
            ...product,
            platform: product.platform.toLowerCase(),
            site: product.site.toLowerCase()
          };
        }
      }
      return null;
    },

    // Get visible columns
    visibleColumns() {
      return this.columnMetaData.filter(col => col.show);
    }
  },
  methods: {
  
    clear:function(event, propertyStr) {
      var _this = this;
      var propertyArr = propertyStr.split(".");
      if(event == '') {
        var property = _this;
        for(var i = 0; i < propertyArr.length; i++) {
          if(i == propertyArr.length - 1) {
            property[propertyArr[i]] = {};
            break;
          }
          property = property[propertyArr[i]];
        }
        //clear
        if(propertyArr[1]  ==="thirdAccount"){
          //埋点
          var logstr = "dashboard_shop_clear_click";
          this.addTrackingLog(logstr);
        }else if(propertyArr[1]  ==="thirdPlatform"){
          //埋点
          var logstr = "dashboard_platfrom_clear_click";
          this.addTrackingLog(logstr);
        }else if(propertyArr[1]  ==="site"){
          //埋点
          var logstr = "dashboard_site_clear_click";
          this.addTrackingLog(logstr);
        }else if(propertyArr[1]  ==="astatus"){
          //埋点
          var logstr = "dashboard_astatus_clear_click";
          this.addTrackingLog(logstr);
        } else if (propertyArr[1] == "threeOrganization") {
          var logstr = "dashboard_organization_clear_click";
          this.addTrackingLog(logstr);
        }

      }else{
        //change
        if(propertyArr[1]  ==="thirdAccount"){
          //埋点
          var logstr = "dashboard_shop_click?shop=("+ event.aname+ ")";
          this.addTrackingLog(logstr);
        }else if(propertyArr[1]  ==="thirdPlatform"){
          //埋点
          var logstr = "dashboard_platfrom_click?platfrom=("+ event.aname+ ")";
          this.addTrackingLog(logstr);
        }else if(propertyArr[1]  ==="site"){
          //埋点
          var logstr = "dashboard_site_click?site=("+ event.aname+ ")";
          this.addTrackingLog(logstr);
        }else if(propertyArr[1]  ==="astatus"){
          //埋点
          var logstr = "dashboard_astatus_click?astatus=("+ event.aname+ ")";
          this.addTrackingLog(logstr);
        } else if (propertyArr[1] == "threeOrganization") {
          var logstr = "dashboard_organization_click?organization=("+ event.aname+ ")";
          this.addTrackingLog(logstr);
        }
      }
      this.searchData();
    },
    changeFilterBy(){
      //埋点
      var logstr = "dashboard_search_click?search_type=" +this.query.filterBy +"&search_content=" + this.query.search;
      this.addTrackingLog(logstr);
      this.searchData();
    },
    changehSearchData(){
      //埋点
      var logstr = "dashboard_search_click?search_type=" +this.query.filterBy +"&search_content=" + this.query.search;
      this.addTrackingLog(logstr);
      this.searchData();
    },
    searchData: function() {
      this.pageDomain.pageIndex = 1;
      this.pageDomain.recordCount = 0;
      this.getDataList();
    },
    getDataList: function() {
      var _this = this;

      var dataMap = {
        "query": _this.query,
        "pageInfo": _this.pageDomain
      };

      _this.searchLoading = true;

      _this.$axios.post('/platformGoods/xaz/selectList', dataMap
          ).then((result) => {
            if(result.flag == true) {
              _this.dataList = result.dataList;
              _this.pageDomain = result.pageInfo;
            }else {
              _this.$message({ showClose: true, message: result.msg, type: 'error'  });
            }

            _this.searchLoading = false;
          });

      setTimeout(function() {
        _this.searchLoading = false;
      }, 10 * 1000);
    },
    queryInit: function() {
      var  _this= this;
      _this.isLoading = true;
      var dataMap = {
        query:_this.query
      };
      _this.$axios.post('/platformGoods/xaz/queryInit', dataMap
      ).then((result) => {
        if(result.flag == true) {
          _this.query = result.query;
          _this.dataList = result.dataList;
          _this.pageDomain = result.pageInfo;
          _this.accountList = result.accountList;
          _this.siteList = result.siteList.slice(1);
          _this.statusList = result.statusList;
          _this.platformList = result.platformList;
          _this.threeOrganizationList = result.threeOrganizationList;
          console.log("dataList",_this.dataList);
        }
        _this.isLoading = false;
        _this.$nextTick(() => {
          _this.getPageCustomization();
        });
      });
    },
    exportExcel() {
      this.$confirm('Do you want to export all records?', 'Export Confirmation', {
        confirmButtonText: 'Export',
        cancelButtonText: 'Cancel',
        type: 'warning',
        showClose: false,
        customClass: 'msgbox-two-btn'
      }).then(() => {
        var dataMap = {
          "query": this.query,
        };
        this.$axios.post('/platformGoods/xaz/exportExcel', dataMap, {responseType:'blob'}).then(async result => {
          if (result.response.headers.flag === 'false') {
            const msg = await result.text();
            this.$message.error(msg);
            return;
          }

          DownloadUtils.download(result, 'dashboard.xlsx');
        })
        .finally(() =>{
        });
      }, () => {
      });
    },
    // Table methods
    handleSizeChange: function(val) {
      //埋点
      var logstr = "dashboard_show_click?page=("+val+")";
      this.addTrackingLog(logstr);
      this.pageDomain.pageSize = val;
      this.getDataList();
    },
    handleSelectionChange(selection) {
      // this.selectedRows = selection.map(item => item.sku);
      this.multipleSelection = selection;
    },
    handleCurrentChange(val) {
      //埋点
      var logstr = "dashboard_turn_page_click";
      this.addTrackingLog(logstr);
      this.getDataList();
    },
    handlePrevClick() {
      console.log('上一页被点击');
      //埋点
      var logstr = "dashboard_turn_page_click";
      this.addTrackingLog(logstr);
    },
    handleNextClick() {
      console.log('下一页被点击');
      //埋点
      var logstr = "dashboard_turn_page_click";
      this.addTrackingLog(logstr);
    },
    handleSortChange(sort) {
      const { prop, order } = sort;
      console.log('排序字段:', prop);
      console.log('排序顺序:', order);
      this.query.sortBy = prop;
      this.query.order = order;
      if(prop  ==="price"){
        //埋点
        var logstr = "ashboard_price_click?sort= dashboard_Price_click?sort="+ order;
        this.addTrackingLog(logstr);
      }else if(prop ==="asevenDaySales"){
        //埋点
        var logstr = "ashboard_price_click?sort= dashboard_sales7_click?sort="+ order;
        this.addTrackingLog(logstr);
      }else if(prop ==="athirtyDaySales"){
        //埋点
        var logstr = "ashboard_price_click?sort= dashboard_sales30_click?sort="+ order;
        this.addTrackingLog(logstr);
      }else if(prop ==="aninetyDaySales"){
        //埋点
        var logstr = "ashboard_price_click?sort= dashboard_sales90_click?sort="+ order;
        this.addTrackingLog(logstr);
      }
      //重新渲染数据
      this.searchData();
    },

    dateFormat: function(row, column, cellValue) {
      if (cellValue != null) {
        var date = new Date(cellValue);
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate()
        );
      } else {
        return "";
      }
    },
    //埋点
    addTrackingLog(eid){
      this.$axios.get('/productPublish/xaz/addTrackingLog', {
        params: {
          e: eid,
        }
      });
    },
    // Column management methods
    isColumnVisible(columnId) {
      const column = this.columns.find(col => col.id === columnId);
      return column && column.visible;
    },

    moveColumnUp(index) {
      if (index === 0) return;
      const temp = this.columns[index];
      this.$set(this.columns, index, this.columns[index - 1]);
      this.$set(this.columns, index - 1, temp);
    },

    moveColumnDown(index) {
      if (index === this.columns.length - 1) return;
      const temp = this.columns[index];
      this.$set(this.columns, index, this.columns[index + 1]);
      this.$set(this.columns, index + 1, temp);
    },

    resetColumns() {
      this.columns = [
        { id: "select", name: "", visible: true },
        { id: "sku", name: "Sku", visible: true },
        { id: "skuName", name: "Sku Name", visible: true },
        { id: "image", name: "Main Image", visible: true },
        { id: "platform", name: "Platform", visible: true },
        { id: "site", name: "Site", visible: true },
        { id: "platformSkuId", name: "Platform Sku ID", visible: true },
        { id: "platformSkuName", name: "Platform Sku Name", visible: true },
        { id: "status", name: "Status", visible: true },
        { id: "price", name: "Price", visible: true },
        { id: "currency", name: "Currency", visible: true },
        { id: "salesQty7", name: "Sales Qty 7", visible: true },
        { id: "salesQty30", name: "Sales Qty 30", visible: true },
        { id: "salesQty90", name: "Sales Qty 90", visible: true },
        { id: "category", name: "Category", visible: true },
        { id: "title", name: "Title", visible: true },
        { id: "description", name: "Description", visible: true },
        { id: "actions", name: "Actions", visible: true },
      ];
    },

    // Search and filter methods
    getSearchPlaceholder() {
      const field = this.searchFields.find(f => f.value === this.searchField);
      return `Search by ${field ? field.label : ''}...`;
    },

    handlePlatformChange() {
      this.selectedSite = 'all';
      this.selectedShop = 'all';
    },

    // Sales chart methods
    handleSalesClick(productName, timeRange) {
      this.selectedProduct = productName;
      this.selectedTimeRange = timeRange;
      this.selectedChartProducts = [`${productName} (${this.getCurrentProductSite(productName)})`];
      this.dialogOpen = true;
      this.generateMockSalesData();
    },

    getCurrentProductSite(productName) {
      const product = this.products.find(p => p.platformSkuName === productName);
      return product ? product.site : '';
    },

    handleCommand(command) {
      if (command.type === 'sales') {
        this.handleSalesClick(command.product, command.timeRange);
      }
    },

    toggleProductSelection(productKey) {
      if (this.selectedChartProducts.includes(productKey)) {
        this.selectedChartProducts = this.selectedChartProducts.filter(p => p !== productKey);
        if (this.selectedChartProducts.length === 0) {
          this.selectedChartProducts = [productKey];
        }
      } else {
        this.selectedChartProducts.push(productKey);
      }
      this.updateAIAnalysis();
    },

    getProductTagStyle(product) {
      const isSelected = this.selectedChartProducts.includes(product.key);
      return {
        backgroundColor: isSelected ? product.color : 'transparent',
        color: isSelected ? 'white' : product.color,
        borderColor: product.color
      };
    },

    generateMockSalesData() {
      // This would generate mock sales data for the chart
      // In a real application, this would be fetched from an API
      this.salesData = {
        '7': Array(7).fill().map((_, i) => ({
          date: new Date(Date.now() - (6 - i) * 24 * 60 * 60 * 1000).toLocaleDateString(),
          "HLVC BASELINE BL K-TRAY (DE)": Math.floor(Math.random() * 50) + 10,
          "HLVC BASELINE BL K-FOLD (DE)": Math.floor(Math.random() * 40) + 8,
          "HLVC BASELINE BL K-END (DE)": Math.floor(Math.random() * 30) + 5,
          "HLVC BASELINE BL K-FOLD (GB)": Math.floor(Math.random() * 35) + 7,
          "CARNATION BLACK WHITE (GB)": Math.floor(Math.random() * 25) + 0,
          "All Products": Math.floor(Math.random() * 150) + 30,
        })),
        '30': Array(30).fill().map((_, i) => ({
          date: new Date(Date.now() - (29 - i) * 24 * 60 * 60 * 1000).toLocaleDateString(),
          "HLVC BASELINE BL K-TRAY (DE)": Math.floor(Math.random() * 50) + 10,
          "HLVC BASELINE BL K-FOLD (DE)": Math.floor(Math.random() * 40) + 8,
          "HLVC BASELINE BL K-END (DE)": Math.floor(Math.random() * 30) + 5,
          "HLVC BASELINE BL K-FOLD (GB)": Math.floor(Math.random() * 35) + 7,
          "CARNATION BLACK WHITE (GB)": Math.floor(Math.random() * 25) + 0,
          "All Products": Math.floor(Math.random() * 150) + 30,
        })),
        '90': Array(90).fill().map((_, i) => ({
          date: new Date(Date.now() - (89 - i) * 24 * 60 * 60 * 1000).toLocaleDateString(),
          "HLVC BASELINE BL K-TRAY (DE)": Math.floor(Math.random() * 50) + 10,
          "HLVC BASELINE BL K-FOLD (DE)": Math.floor(Math.random() * 40) + 8,
          "HLVC BASELINE BL K-END (DE)": Math.floor(Math.random() * 30) + 5,
          "HLVC BASELINE BL K-FOLD (GB)": Math.floor(Math.random() * 35) + 7,
          "CARNATION BLACK WHITE (GB)": Math.floor(Math.random() * 25) + 0,
          "All Products": Math.floor(Math.random() * 150) + 30,
        }))
      };

      this.updateAIAnalysis();
    },

    updateAIAnalysis() {
      // In a real application, this would be fetched from an AI service
      // Here we're just using a static analysis
      const trend = Math.random() > 0.5 ? 'up' : 'down';
      const trendText = trend === 'up' ? '上升' : '下降';
      const trendPercentage = (Math.random() * 10).toFixed(1);

      this.salesTrend = trend;
      this.totalSales = Math.floor(Math.random() * 10000) + 1000;

      this.aiAnalysis = `基于${this.selectedPlatformChart === 'all' ? '全平台' : this.selectedPlatformChart.toUpperCase()}${this.selectedChartProducts.length}个SKU的历史数据分析，销量趋势呈现${trendText}态势，
      环比${trendText}${trendPercentage}%。近期日均销量${Math.round(this.totalSales / 30)}件，
      ${trend === 'up' ? '预计未来30天销量将持续增长' : '建议关注销量下滑原因'}。
      主要影响因素：
      1. ${trend === 'up' ? '市场需求增加' : '市场竞争加剧'}
      2. ${trend === 'up' ? '广告投放效果良好' : '广告转化率下降'}
      3. ${trend === 'up' ? '好评率持续提升' : '产品竞争力下降'}`;
    },
    //拿页面自定义默认json
    getPageCustomization(){
      var _this = this;
       _this.$axios.get('/pageCustomization/xaz/getPageCustomization?pageNumber='+ '20250324000001').then((result) => {
        if(result.flag == true) {
          this.entryColumnMeta = result.pageCustomization.content;//保存一份原始的json
          this.columnMetaData = JSON.parse(this.entryColumnMeta);// 处理json 给table 渲染
          this.columnMetaDataList = JSON.parse(this.entryColumnMeta); // 处理json 给编辑弹框渲染
         
          console.log( " this.columnMetaData",this.columnMetaData);
        }
        _this.$nextTick(() => {
          // _this.queryInit();
          _this.$refs.dataTable.doLayout();
        });
      });
    },
    allowDrop(draggingNode, dropNode, type) {
      // 仅允许Tree节点上下拖动
      return type !== 'inner'
    },
    // Tree 拖动时更新表格
    handleDrop() {
      this.tableKey++
    },
    resetDefaultColumns(){
      this.columnMetaData = JSON.parse(this.entryColumnMeta);;//再次初适化原始的json
      this.columnMetaDataList = JSON.parse(this.entryColumnMeta);
      this.columnManagerOpen = false;
    },
    // Column management methods
    isDealColumnVisible(name) {
      const column = this.columnMetaData.find(col => col.name === name);
      return column && column.show;
    },
    async savePageCustomization(){
      this.columnManagerOpen = false;

      this.savePageCustomizationLoading = true;
      try {
        // 发送请求
        var dataMp = {
            pageNumber: '20250324000001',
            content: JSON.stringify(this.columnMetaDataList)
        }
        const response = await this.$axios.post('/pageCustomization/xaz/savePageCustomization', dataMp);
        if (!response.flag) {
          this.$message.error(response.msg);
          return;
        }else{
          this.columnMetaData = this.columnMetaDataList;//将弹框的修改赋值给table
          this.drawKey++;
          this.$message.success("success"); 
        }
        
      } catch (error) {
        // 处理错误
        this.$message.error(error); 
      } finally {
        this.columnManagerOpen = false;
        this.savePageCustomizationLoading = false;
      }

      
    },

    toggleDropdown(id) {
      if (this.openDropdown === id) {
        this.openDropdown = null;
      } else {
        this.openDropdown = id;
      }
    },

    closeAllDropdowns() {
      this.openDropdown = null;
    },
    analyticsData(row) {
      if(row.merchandise && row.merchandise.anumber){
        this.$router.push({ name: 'ProductAnalytics', query: { aid: row.aid } });
      }else{
        this.$message.error("This data entry is missing SKU/SKU Name data, making data analysis impossible. Please maintain the SKU mapping relationship.");
      }
      
    },

  },
  created() {
    this.queryInit();
    // this.getPageCustomization();
  },
}
</script>

<style scoped>
.app-container {
  display: flex;
  min-height: 100vh;
  background-color: #f5f7fa;
}

.sidebar {
  background-color: #fff;
  border-right: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.sidebar-header {
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.logo {
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(to right, #409EFF, #8A2BE2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.sidebar-menu {
  border-right: none;
}

.sidebar-footer {
  margin-top: auto;
  border-top: 1px solid #f0f0f0;
}

.content-container {
  background-color: rgb(249 250 251);
}

.main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main-header {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.header-title {
  font-size: 20px;
  font-weight: bold;
  background: linear-gradient(to right, #409EFF, #8A2BE2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner {
  background: linear-gradient(to right, #1e3a8a, #312e81);
  color: white;
  padding: 30px;
  border-radius: 0.75rem;
  margin-bottom: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.banner-title {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.banner-subtitle {
  opacity: 0.9;
  color: rgb(219, 234, 254);
}

.filters-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 12px;
}

.filters-left {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filters-right {
  display: flex;
  gap: 8px;
}

.search-container {
  display: flex;
}

.table-card {
  margin-bottom: 24px;
  border: 1px solid rgb(229, 231, 235);
  border-radius: 0.75rem;
}

.product-image {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
  padding: 4px;
}

.product-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  display: inline-block;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.pagination-info {
  font-size: 14px;
  color: #606266;
}

.column-manager-content {
  max-height: 70vh;
  overflow-y: auto;
}

.column-manager-description {
  color: #909399;
  margin-bottom: 16px;
}

.column-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.column-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #f5f7fa;
  border-radius: 4px;
}

.column-item-left {
  display: flex;
  align-items: center;
}

.column-item-right {
  display: flex;
  gap: 4px;
}

.sales-dialog-header {
  margin-bottom: 16px;
}

.sales-dialog-filters {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.search-input {
  flex: 1;
}

.product-info-card {
  margin-bottom: 16px;
}

.product-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.product-info-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.product-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

.product-tag {
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
}

.product-tag-sales {
  margin-left: 8px;
  padding: 0 6px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

.chart-container {
  height: 400px;
  margin-bottom: 16px;
}

.chart-placeholder {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
  border-radius: 4px;
  color: #909399;
}

.ai-analysis-card {
  margin-top: 16px;
  background: linear-gradient(to right, #f3e5f5, #e3f2fd);
}

.ai-analysis-header {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: bold;
}

.ai-analysis-content {
  white-space: pre-line;
  color: #606266;
}

/deep/ .el-pagination .el-select .el-input {
  width: 100px !important;
  margin: 0 5px;
}

/* 定义 hover 状态下的文字颜色 */
.hover-text-blue-800:hover {
  color: rgb(30, 64, 175); /* 对应 Tailwind 的 hover:text-blue-800 */
}

/* 定义默认状态下的文字颜色 */
.text-blue-600 {
  color: rgb(37, 99, 235); /* 对应 Tailwind 的 text-blue-600 */
}

.el-button.white-button {
  background-color: hsl(0, 0%, 100%);
  color: black;
  border-radius: calc(0.5rem - 2px);
  border: 1px solid hsl(240, 5.9%, 90%);
}

.el-button.white-button:hover {
  background-color: hsl(240, 4.8%, 95.9%) !important;
}

.el-select.platform-select {
  width: 140px;
}
.el-select.site-select {
  width: 70px;
}
.el-select.status-select {
  width: 200px;
}
.el-select.filterby-select {
  width: 160px;
}

/deep/ .el-table .cell {
  white-space: normal !important;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: rgb(107, 114, 128);
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
}

/deep/ .el-table td.el-table__cell.text-gray-500 div {
  color: rgb(107, 114, 128);
}
/deep/ .el-table td.el-table__cell.text-gray-700 div {
  color: rgb(55, 65, 81);
}

/deep/ .el-table td.el-table__cell.status-cell .cell {
  text-overflow: unset;
}

.el-tag.active-status {
  border-radius: 9999px;
  color: rgb(22, 101, 52);
  background-color: rgb(220, 252, 231);
}
.el-tag.active-status:hover {
  background-color: rgb(187, 247, 208);
}
.el-tag.inactive-status {
  border-radius: 9999px;
  color: rgb(31, 41, 55);
  background-color: rgb(243, 244, 246);
}
.el-tag.inactive-status:hover {
  background-color: rgb(229, 231, 235);
}

/deep/ .el-pagination button {
  background-color: black;
  color: white;
}
/deep/ .el-pagination button:disabled {
  background-color: black;
  color: white;
}
</style>
<style>
.platform-select-dropdown .el-scrollbar {
  width: 140px;
}
.site-select-dropdown .el-scrollbar {
  width: 70px;
}
.status-select-dropdown .el-scrollbar {
  width: 200px;
}
.filterby-select-dropdown .el-scrollbar {
  width: 160px;
}
.msgbox-two-btn .el-message-box__btns button:first-child {
  background-color: hsl(0, 0%, 100%);
  color: black;
  border-radius: calc(0.5rem - 2px);
  border: 1px solid hsl(240, 5.9%, 90%);
}
.msgbox-two-btn .el-message-box__btns button:first-child:hover {
  background-color: hsl(240, 4.8%, 95.9%) !important;
}
.msgbox-two-btn .el-message-box__btns button.el-button--primary {
  background-color: hsl(222.2, 47.4%, 11.2%);
  color: hsl(210, 40%, 98%);
  border-radius: calc(0.5rem - 2px);
}
.msgbox-two-btn .el-message-box__btns button.el-button--primary:hover {
  background-color: hsla(222.2, 47.4%, 11.2%, 0.9) !important;
}

.dialogTreeTitle{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #e3e4e5;
}
.el-dialog .el-dialog__body .dialogTree {
    height: auto !important;
    overflow: auto;
    position: relative;
}
.el-dialog .el-dialog__body .dialogTree .el-tree .el-tree-node .el-tree-node__content .el-tree-node__expand-icon.is-leaf {
    width: 1%;
}
.el-dialog .el-dialog__body .dialogTree .el-tree  .el-tree-node{
  border-bottom: 1px solid #e3e4e5;
}
.product-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-list-image {
  max-width: 100%;
  max-height: 100px; /* 根据需要调整高度 */
}


/* Dropdown Menu */
.dropdown-container {
  position: relative;
  width: 100%;
  height: 70px;
}

.more-btn {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.more-btn:hover {
  background-color: #f5f5f5 !important; /* 浅灰色背景 */
  border-color: #bbb !important; /* 加深边框颜色 */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 添加轻微阴影 */
}

.dropdown-menu {
  position: absolute;
  top: 28px;
  right: 5px;
  background-color: white;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 85px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #333;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.dropdown-item:not(:last-child) {
  border-bottom: 1px solid #eaeaea;
}

</style>
