import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,  // 用于存储用户信息
    currentPage: 1,  // 获取当前页面信息
    currentQuery: {
      platform: null,
      site: null,
      skuNumber: null
    },  // 获取History query信息
  },
  getters: {
    user: (state) => state.user,  // 获取用户信息
    currentPage: (state) => state.currentPage,// 获取当前页面信息
    currentQuery: (state) => state.currentQuery,// 获取History query信息
  },
  mutations: {
    setUser(state, user) {
      state.user = user;  // 修改用户信息
    },
    clearUser(state) {
      state.user = null;  // 清除用户信息
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setCurrentQuery(state, query) {
      state.currentQuery = query;
    },
  },
  actions: {
    
  },
  modules: {
  }
})
